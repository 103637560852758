@import "../../theme/index.module.scss";

.favourite {
  display: inline-block;
  fill: $red;
  transition: fill .3s;
  &:hover {
    fill: $red-oxide;
    &:before {
      border-color: $red-oxide;
    }
  }
}

.favourite_rounded {
  composes: favourite;
  overflow: visible !important;
  position: relative;
  & > .Icon {
    padding: 10px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid $red;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: border-color .3s;
  }
}

.bookmark {
  display: inline-block;
  fill: $tangerine;
  transition: fill .3s;
  &:hover {
    fill: darken($tangerine, 15%);
    &:before {
      border-color: darken($tangerine, 15%);
    }
  }
}

.bookmark_rounded {
  composes: bookmark;
  overflow: visible !important;
  position: relative;
  & > .Icon {
    padding: 10px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid $tangerine;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: border-color .3s;
  }
}

.light_opacity {
  display: inline-block;
  fill: $black;
  opacity: 1;
  transition: opacity .3s;
  &:hover {
    opacity: 0.5;
  }
}

.light_opacity_rounded {
  composes: light_opacity;
  overflow: visible !important;
  position: absolute;
  cursor: pointer;
  & > .Icon {
    padding: 10px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid $black;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: border-color .3s;
  }
}

.dark {
  display: inline-block;
  fill: $silver;
  transition: fill .3s;
  &:hover {
    fill: $abbey;
    &:before {
      border-color: $abbey;
    }
  }
}

.dark_rounded {
  composes: dark;
  overflow: visible !important;
  position: relative;
  & > .Icon {
    padding: 10px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid $silver;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: border-color .3s;
  }
}

.dark_rounded_bg {
  display: inline-block;
  fill: $white;
  background: rgba(0, 0, 0, 0.5);
  overflow: visible !important;
  position: relative;
  border-radius: 50%;
  &:after, &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid $white;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: transform .3s, opacity .3s;
  }
  &:before {
    opacity: 1;
  }
  &:after {
    opacity: 0;
  }
  & > .Icon {
    padding: 12px;
  }
  &:active {
    &:before {
      transform: scale(1.5);
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
}

.red {
  display: inline-block;
  fill: $white;
  transition: fill .3s;
  &:hover {
    fill: $abbey;
    &:before {
      border-color: $abbey;
    }
  }
}

.redOxide {
  display: inline-block;
  fill: $red-oxide;
  transition: fill .3s;
}

.red_rounded {
  composes: red;
  overflow: visible !important;
  position: relative;
  & > .Icon {
    padding: 10px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid $white;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: border-color .3s;
  }
}

.white {
  display: inline-block;
  fill: $dove-gray;
  transition: fill .3s;
  &:hover {
    fill: $abbey;
    &:before {
      border-color: $abbey;
    }
  }
}

.whit {
  display: inline-block;
  fill: $dove-gray;
  transition: fill .3s;
  &:hover {
    fill: $abbey;
    &:before {
      border-color: $abbey;
    }
  }
}

.white_rounded {
  composes: whit;
  overflow: visible !important;
  position: relative;
  & > .Icon {
    padding: 10px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid $dove-gray;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: border-color .3s;
  }
}

// Social

$social: (
  facebook: (color: #3b5998, scale: 20px, padding: 10px),
  whatsapp: (color: #25D366, scale: 20px, padding: 10px),
  twitter: (color: #55acee, scale: 20px, padding: 10px),
  linkedin: (color: #007bb5, scale: 20px, padding: 10px),
  youtube: (color: #bb0000, scale: 20px, padding: 10px),
  google: (color: #dd4b39, scale: 25px, padding: 7px),
  vodacom: (color: $red, scale: 20px, padding: 10px),
  email: (color: #000000, scale: 20px, padding: 10px)
);

.socialIcon {
  display: inline-block;
  fill: $silver;
  overflow: visible !important;
  position: relative;
  border-radius: 50%;
  transition: fill .3s, border-color .3s;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid $silver;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: border-color .3s;
  }
}

@each $icon, $value in $social {
  .#{$icon} {
    composes: socialIcon;
    & > .Icon {
      padding: map_get($value, padding);
    }
    &:hover {
      fill: $white;
      &:before {
        border-color: $white;
      }
    }
  }

  .#{$icon}_color {
    composes: socialIcon;
    & > .Icon {
      padding: map_get($value, padding);
    }
    &:hover {
      fill: map_get($value, color);
      &:before {
        border-color: map_get($value, color);
      }
    }
  }
}
